.hp-become-customer {
  background-color: #fff;
  padding-top: var(--space-40);
}
.hp-become-customer__title {
  margin-bottom: var(--space-56);
}
.hp-become-customer__grid {
  position: relative;
  display: grid;
  grid-template: "cta cta" 0.8fr "secondary1 secondary2" 0.6fr "offers offers" 1fr/1fr 1fr;
  gap: 10px;
  grid-auto-flow: row;
}
@media (min-width: 768px) {
  .hp-become-customer__grid {
    gap: 20px;
    grid-template: "offers secondary1 secondary2" 1fr "offers cta cta" 1fr/1.2fr 0.9fr 0.9fr;
  }
}
.hp-become-customer__grid .block {
  --main-color: var(--color-blue-1100);
  --txt-color: #fff;
  position: relative;
  border-radius: var(--radius-main);
  box-shadow: 0 1px 11px -5px rgba(0, 0, 0, 0.75);
  background-color: var(--main-color);
  color: var(--txt-color);
  padding: var(--space-24);
  display: grid;
  align-items: start;
  grid-template-rows: 40px auto auto;
}
.hp-become-customer__grid .block p {
  font-size: 18px;
}
.hp-become-customer__grid .block strong,
.hp-become-customer__grid .block b {
  font-weight: 500;
}
.hp-become-customer__grid .block__title {
  color: inherit;
  margin-block: 1em;
}
.hp-become-customer__grid .block__link {
  text-transform: uppercase;
  font-weight: 500;
  align-self: flex-end;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .hp-become-customer__grid .block__link {
    justify-self: flex-start;
  }
}
.hp-become-customer__grid .block .cta-primary-inverted {
  text-transform: none;
}
.hp-become-customer__grid .block__icon {
  height: 40px;
  width: 40px;
}
.hp-become-customer__grid .block__icon img {
  width: 100%;
  height: auto;
}
.hp-become-customer__grid .block--offers {
  grid-area: offers;
}
.hp-become-customer__grid .block--offers .block__content p {
  margin-bottom: 1rem;
}
.hp-become-customer__grid .block--offers .block__content p:first-child {
  margin-top: 1rem;
}
.hp-become-customer__grid .block--secondary {
  --main-color: #fff;
  --txt-color: var(--color-neutral-1200);
}
.hp-become-customer__grid .block--secondary:first-of-type {
  grid-area: secondary1;
}
.hp-become-customer__grid .block--secondary:last-of-type {
  grid-area: secondary2;
}
.hp-become-customer__grid .block--subscription {
  --main-color: var(--color-coral-700);
  grid-area: cta;
}
.hp-become-customer__grid .block--subscription a {
  color: var(--txt-color);
}